import { getUserData } from '@common/utils';
import Error404Form from '@features/common/Error404Form/Error404Form';
import Marquee from '@features/common/Marquee/Marquee';
import BaseLayout from '@layouts/Base/BaseLayout';
import { useEffect, useState } from 'react';
import { Error404FormContainer } from './Error404.styles';

const Error404 = (): JSX.Element => {
  const [name, setName]: any = useState('');

  const VerticalMarquee = <Marquee type={'vertical'} />;
  const HorizontalMarquee = <Marquee type={'horizontal'} />;

  useEffect(() => {
    const getUserDataFromCookie = getUserData(document.cookie);
    if (getUserDataFromCookie) {
      setName(getUserDataFromCookie?.name);
    }
  }, []);

  return (
    <BaseLayout showFooter={true} background={VerticalMarquee} aboveGrid={HorizontalMarquee} headerProps={{ userName: name }}>
      <Error404FormContainer>
        <Error404Form />
      </Error404FormContainer>
    </BaseLayout>
  );
};

export default Error404;
