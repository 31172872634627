import Button from '@components/Button/Button';
import { Heading1, Heading5 } from '@components/Texts/Texts';
import tw, { styled } from 'twin.macro';

export const Container = styled.div`
  ${tw`sm:text-center md:text-center`};
`;

export const Heading = styled(Heading1)`
  ${tw`text-light-high mb-3`};
`;

export const Details = styled(Heading5)`
  ${tw`text-light-low`};
`;

export const StyledButton = styled(Button)`
  ${tw`mt-6 uppercase lg:w-auto xl:w-auto`};
`;
