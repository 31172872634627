import { SeoLink } from '@common/SeoLink';
import Routes from '@constants/routes';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { FORM_BUTTON, FORM_DETAILS, FORM_HEADING, I18N_404_CONTEXT } from './Error404Form.constants';
import { Container, Details, Heading, StyledButton } from './Error404Form.styles';

const Error404Form = (): JSX.Element => {
  const router = useRouter();
  const { t } = useTranslation(I18N_404_CONTEXT);

  const goToHomepage = async () => {
    router.push(Routes.HOME);
  };

  return (
    <Container>
      <Heading>{t(FORM_HEADING)}</Heading>
      <Details>{t(FORM_DETAILS)}</Details>
      <StyledButton text={t(FORM_BUTTON)} onClick={goToHomepage} />
      <SeoLink href={Routes.HOME} />
    </Container>
  );
};

export default Error404Form;
